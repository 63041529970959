// Write your code!
setTimeout(function () {
  var msnry = new Masonry('.grid');
  msnry.layout();
}, 100);

setTimeout(function () {
  var msnry = new Masonry('.grid');
  msnry.layout();
}, 300);

setTimeout(function () {
  var msnry = new Masonry('.grid');
  msnry.layout();
}, 1000);

setTimeout(function () {
  var msnry = new Masonry('.grid');
  msnry.layout();
}, 5000);
